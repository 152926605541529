<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

import {format} from "@/util/common";

console.log("height", window.screen.height)
console.log("innerHeight", window.innerHeight)
console.log("outerHeight", window.outerHeight)
console.log("clientHeight", document.documentElement.clientHeight)

console.log("width", window.screen.width)
console.log("innerWidth", window.innerWidth)
console.log("outerWidth", window.outerWidth)
console.log("clientWidth", document.documentElement.clientWidth)

let list = [{
  diseaseName: "肺癌",
  deathNum: 0,
  mortality: '5%'
}]

let data = {
  name: "",
  age: 0,
  type: null
}

console.log(format(list))
console.log(format(data))
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
